<template>
  <div id="train" class="world">
    <ul class="tab flex">
      <li
        v-for="(item, index) in tab"
        :key="index"
        :class="{ active: active == index }"
        @click="tabs(index)"
      >
        {{ item }}
      </li>
    </ul>
    <div v-if="active == 0">
      <ul
        class="list flex-wrap loads"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
      >
        <li class="box cursorP" v-for="item in trainList" :key="item.id">
          <router-link
            :to="'/training/trainDetail/?id=' + item.id"
            target="_blank"
            class="flex"
          >
            <div class="banner relative">
              <img :src="item.picurl" alt class="err_image" />
              <div class="status come" v-if="item.ing == 2">已报名</div>
              <div class="status end" v-else>已结束</div>
            </div>
            <div class="info">
              <h2>{{ item.trainName }}</h2>
              <p class="line_clamp1" v-if="item.disType">
                适合 {{ item.disType | parseS }}
              </p>
              <p>
                <span
                  >培训时间：{{ item.appplyBeginTime }}—{{
                    item.appplyEndTime
                  }}</span
                >
              </p>
              <div class="bottom flex">
                <span>{{ item.applyNumber }}人报名</span>
                <span>{{ item.trainAddress }}</span>
              </div>
            </div>
          </router-link>
        </li>
      </ul>
      <div class="pages">
        <el-pagination
          background
          class="work_pagination"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="params.pageNum"
          :page-size="params.pageSize"
          layout="prev, pager, next, total"
          prev-text="上一页"
          next-text="下一页"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <div
      v-if="active == 1"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
    >
      <ul class="list online flex-wrap">
        <li
          class="box flex cursorP"
          v-for="(item, index) in lookList"
          :key="item.id"
          @click="
            goRouter(
              '/training/publicDetail/',
              item.couseid,
              item.seweareid,
              item.cousetype,
              item.picurl
            )
          "
        >
          <div class="banner">
            <img :src="item.logo" alt class="err_image" />
          </div>
          <div class="info">
            <h2>{{ item.sewearename }}</h2>
            <p class="p1" v-if="item.cousetype == '3'">课件类型：视频</p>
            <p class="p1" v-else>课件类型：文档</p>
            <div class="online_bottom">
              <p class="texts" v-if="item.cousetype == '3'">
                <!-- item.currenttime | percentage(item.alltime,index -->
                <span
                  >已学{{
                    percentage(item.currenttime, item.alltime, index)
                  }}</span
                >
              </p>
              <P class="texts" v-else>
                <span>已学 0%</span>
              </P>
              <div class="schedule" v-if="item.cousetype == '3'">
                <div :style="{ width: percentageList[index] + '%' }"></div>
              </div>
              <div class="schedule" v-else>
                <div :style="{ width: 0 + '%' }"></div>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="pages" v-if="active == 1">
        <el-pagination
          background
          class="work_pagination"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="params.pageNum"
          :page-size="params.pageSize"
          layout="prev, pager, next, total"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <div v-if="active == 2" class="eltree">
      <el-tree
        :data="data"
        :props="defaultProps"
        @node-click="handleNodeClick"
      ></el-tree>
    </div>
  </div>
</template>

<script>
export default {
  name: "training",
  data() {
    return {
      active: 0,
      loading: false,
      tab: ["我报名的", "我学习的"],
      //  tab: ["我报名的", "我学习的",'我测试'],
      total: 0,
      trainList: [],
      lookList: [],
      percentageList: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      Num: 10,
      firstNum: 1,
      secondeNum: 2,
      params: {
        pageNum: 1,
        pageSize: 8,
      },
      data: [
        {
          label: "一级 1",
          children: [
            {
              label: "二级 1-1",
              children: [
                {
                  label: "三级 1-1-1",
                },
              ],
            },
          ],
        },
        {
          label: "一级 2",
          children: [
            {
              label: "二级 2-1",
              children: [
                {
                  label: "三级 2-1-1",
                },
              ],
            },
            {
              label: "二级 2-2",
              children: [
                {
                  label: "三级 2-2-1",
                },
              ],
            },
          ],
        },
        {
          label: "一级 3",
          children: [
            {
              label: "二级 3-1",
              children: [
                {
                  label: "三级 3-1-1",
                },
              ],
            },
            {
              label: "二级 3-2",
              children: [
                {
                  label: "三级 3-2-1",
                },
              ],
            },
          ],
        },
      ],
      defaultProps: {
        children: "children",
        label: "label",
      },
    };
  },
  filters: {
    parseS: function(value) {
      if (value) {
        return JSON.parse(value).join(",");
      }
    },
  },
  methods: {
    handleNodeClick(data) {
      console.log(data);
    },
    percentage: function(currenttime, alltime, index) {
      let i = currenttime / alltime;
      console.log(currenttime, alltime, i);
      if (i > 1) {
        return "100%";
      }
      let x = Math.round(i * 100);
      this.percentageList[index] = x;
      return x + "%";
    },
    tabs(i) {
      this.active = i;
      if (this.active == 0) {
        this.getMyapplyTrain();
      }
      if (this.active == 1) {
        // this.division(this.lookList.currenttime,this.lookList.alltime);
        this.getUserlookList();
      }
    },

    //我报名的培训
    async getMyapplyTrain() {
      this.loading = true;
      try {
        let res = await this.$api.getMyapplyTrain(this.params);
        console.log("报名培训", res);
        if (res.data.success) {
          this.trainList = res.data.data.records;
          this.total = res.data.data.total;
          this.loading = false;
        } else {
          this.$message.error(res.data.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
    //我学习的
    async getUserlookList() {
      // this.loading = true;
      try {
        let res = await this.$api.getUserlookList(this.params);
        console.log("我学习的", res);
        if (res.data.success) {
          this.lookList = res.data.data.records;
          this.total = res.data.data.total;
          this.loading = false;
        } else {
          this.$message.error(res.data.msg);
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    goRouter(url, id, seweareid, cousetype, urls) {
      console.log(cousetype);
      if (cousetype == "3") {
        this.query = {
          id: id,
          seweareid: seweareid,
        };
      } else {
        window.open(urls);
      }
      const { href } = this.$router.resolve({
        path: url,
        query: this.query,
      });
      window.open(href, "_blank");
    },
    //分页
    handleCurrentChange(val) {
      this.params.pageNum = val;

      if (this.active == 0) {
        this.getMyapplyTrain();
      }
      if (this.active == 1) {
        this.getUserlookList();
      }
    },
    handleSizeChange(val) {
      if (this.active == 0) {
        this.pageSize = val;
        this.pageNum = 1;
        this.getMyapplyTrain();
      }
      if (this.active == 1) {
        this.pageSize = val;
        this.pageNum = 1;
        this.getUserlookList();
      }
    },
  },
  created() {
    this.tabs(0);
    this.getMyapplyTrain();
  },
};
</script>

<style scoped lang="less">
@import "~assets/css/center/center.less";
#train .list li .info .texts {
  width: 100px !important;
}
.p1 {
  width: 100%;
  color: #00924c;
}
.eltree {
  /deep/ .el-tree-node {
    background: #ccc;
  }
  /deep/ .el-tree-node__label:nth-child(3n) {
    background: red;
  }
}
</style>
